import { GET_CATEGORIES_POST } from '../action/Category';
import {
  ALL_POST_DATA_DATA,
  GET_ALL_POST_LOADER,
  GET_LATEST_NEWS_DATA,
  GET_POST_LOADER,
  GET_POST_SEARCH_DATA,
  GET_POST_SEARCH_DATA_COUNT,
  GET_SINGLE_POST_LOADER,
  POST_DATA_COUNT,
  POST_DATA_DATA,
  SEARCH_POST_LOADER,
  SINGLE_POST_DATA_DATA,
  SINGLE_POST_DATA_NAVIAGTE,
} from '../action/Post';

const initialState = {
  getAllPostLoader: false,
  getAllPost: false,
  singlePostLoader: true,
  singlePost: false,
  anwanPostData: false,
  anwanPostCount: 0,
  anwanNavigate: false,
  getPostLoader: true,
  getLatestNewsData: true,
  getPostSearchLoader: false,
  getPostSearchData: false,
  searchPostDataCount: 0,
  getCategoriesPostData: false,
};

const ContentReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_POST_LOADER:
      return {
        ...state,
        getAllPostLoader: action.payload,
      };
    case ALL_POST_DATA_DATA:
      return {
        ...state,
        getAllPost: action.payload,
      };
    case POST_DATA_DATA:
      return {
        ...state,
        anwanPostData: action.payload,
      };
    case POST_DATA_COUNT:
      return {
        ...state,
        anwanPostCount: action.payload,
      };
    case GET_SINGLE_POST_LOADER:
      return {
        ...state,
        singlePostLoader: action.payload,
      };
    case SINGLE_POST_DATA_DATA:
      return {
        ...state,
        singlePost: action.payload,
      };

    case SINGLE_POST_DATA_NAVIAGTE:
      return {
        ...state,
        anwanNavigate: action.payload,
      };
    case GET_POST_LOADER:
      return {
        ...state,
        getPostLoader: action.payload,
      };
    case GET_LATEST_NEWS_DATA:
      return {
        ...state,
        getLatestNewsData: action.payload,
      };
    case SEARCH_POST_LOADER:
      return {
        ...state,
        getPostSearchLoader: action.payload,
      };
    case GET_POST_SEARCH_DATA:
      return {
        ...state,
        getPostSearchData: action.payload,
      };
    case GET_POST_SEARCH_DATA_COUNT:
      return {
        ...state,
        searchPostDataCount: action.payload,
      };
    case GET_CATEGORIES_POST:
      return {
        ...state,
        getCategoriesPostData: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
export default ContentReducer;

import axios from 'axios';

// Get All Book
export const GET_ALL_BOOK_LOADER = 'GET_ALL_BOOK_LOADER';
export const getAllBookLoader = bool => ({
  type: 'GET_ALL_BOOK_LOADER',
  payload: bool,
});

export const getAllBookAction = () => {
  return async dispatch => {
    dispatch(getAllBookLoader(true));

    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `/api/v1/admin/books/allgetbook`,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    axios
      .request(config)
      .then(response => {
        dispatch(getAllBookLoader(false));
        dispatch(createBookData(response.data.data));
        // clearStates()
      })
      .catch(error => {
        dispatch(getAllBookLoader(false));
      });
  };
};

export const BOOK_DATA = 'BOOK_DATA';
export const createBookData = payload => ({
  type: 'BOOK_DATA',
  payload: payload,
});

// get single book
export const GET_SINGLE_BOOK_LOADER = 'GET_SINGLE_BOOK_LOADER';
export const getSingleBookLoader = payload => ({
  type: 'GET_SINGLE_BOOK_LOADER',
  payload: payload,
});

export const getSingleBookAction = id => {
  return async dispatch => {
    // delete obj._id
    // delete obj.cover_img
    dispatch(getSingleBookLoader(true));

    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `/api/v1/admin/books/getbook/${id}`,
      headers: {},
    };

    axios
      .request(config)
      .then(response => {
        dispatch(getSingleBookLoader(false));
        dispatch(getSingleBookData(response.data.data));

        // clearStates()
      })
      .catch(error => {
        dispatch(getSingleBookLoader(false));
      });
  };
};

export const GET_SINGLE_BOOK_DATA = 'GET_SINGLE_BOOK_DATA';
export const getSingleBookData = payload => ({
  type: 'GET_SINGLE_BOOK_DATA',
  payload: payload,
});

// get book volume
export const GET_BOOK_VOLUMES_LOADER = 'GET_BOOK_VOLUMES_LOADER';
export const getBookVolumesLoader = payload => ({
  type: 'GET_BOOK_VOLUMES_LOADER',
  payload: payload,
});

export const getBookVolumesAction = id => {
  return async dispatch => {
    // delete obj._id
    // delete obj.cover_img
    dispatch(getBookVolumesLoader(true));

    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `/api/v1/admin/books/getvolumes/${id}`,
      headers: {},
    };

    axios
      .request(config)
      .then(response => {
        dispatch(getBookVolumesLoader(false));
        dispatch(getBookVolumesData(response.data.data));
        // clearStates()
      })
      .catch(error => {
        dispatch(getBookVolumesLoader(false));
      });
  };
};

export const GET_BOOK_VOLUMES_DATA = 'GET_BOOK_VOLUMES_DATA';
export const getBookVolumesData = payload => ({
  type: 'GET_BOOK_VOLUMES_DATA',
  payload: payload,
});

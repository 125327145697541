import { GET_ARTICLE_COUNT, GET_ARTICLE_DATA, GET_ARTICLE_LOADER } from './../action/Articles';

const initialState = {
  ArticlesData: [],
  ArticlesCount: 0,
  getArticleLoader: true,
};

const ContentReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ARTICLE_DATA:
      return {
        ...state,
        ArticlesData: action.payload,
      };
    case GET_ARTICLE_COUNT:
      return {
        ...state,
        ArticlesCount: action.payload,
      };
    case GET_ARTICLE_LOADER:
      return {
        ...state,
        getArticleLoader: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
export default ContentReducer;

import React from 'react';
import './index.css';
import PolygonDown from '../../assests/images/PolygonDown.png';
function Tooltip({ text, position }) {
  return (
    <div className={`bg-[#D3DAD6] rounded-lg py-2 px-4 tooltip font-bold`} style={{ ...position }}>
      {text}
      <img src={PolygonDown} className="tooltipArrow" />
    </div>
  );
}

export default Tooltip;

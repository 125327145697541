import React, { useEffect, useState } from 'react';

import Header from '../../constant/Header';
import Footer from '../../constant/footer';
import ScrollToTop from '../../constant/ScrollToTop';
import { useDispatch, useSelector } from 'react-redux';
import { getSinglePostAction } from '../../module/action/Post';
import { getPersianDayOfWeek, persianDate } from '../../module/ConstantFunctions';
import { useNavigate } from 'react-router';
import Spinner from '../../constant/Spinner';
import SinlgeHadith from './SinlgeHadith';
function HadithOfTheDay() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const singlePost = useSelector(e => e.Hadith.hadithOfTheDayData);
  const singlePostLoader = useSelector(e => e.Hadith.hadithOfTheDayLoader);

  const [postDetail, setPostDetail] = useState({});

  const [activeAnwan, setActiveAnwan] = useState(false);
  const [redirectURL, setRedirectURL] = useState('');

  useEffect(() => {
    const url = new URL(window.location);
    const post = url.searchParams.get('postId');
    const bookId = url.searchParams.get('bookId');
    const redirectUrl = url.searchParams.get('redirectUrl');
    setRedirectURL(redirectUrl);

    dispatch(getSinglePostAction(post, bookId));

    const handlePopState = event => {
      // Your custom functionality here
      navigate(redirectUrl);
      // Perform your custom actions when the back button is clicked
    };

    window.onpopstate = handlePopState;

    // Clean up the event listener when the component unmounts
    return () => {
      window.onpopstate = null;
    };
  }, []);

  useEffect(() => {
    if (singlePost && singlePost.length) {
      setPostDetail(singlePost[0]);
    }
  }, [singlePost]);

  return (
    <div className="relative h-min-100">
      <Header page={0} limit={2} />
      {singlePostLoader ? (
        <Spinner />
      ) : (
        <SinlgeHadith
          navFunction={false}
          next={false}
          previous={false}
          activeAnwan={activeAnwan}
          postDetail={postDetail}
          setPostDetail={setPostDetail}
          showCatTree={false}
          head={
            <div className="mt-10 mb-6 flex justify-between">
              <p
                onClick={() => navigate('/hadith')}
                className=" mt-2.5 underline underline-offset-4 text-base font-bold cursor-pointer">
                أحادیث روز
              </p>
              <div>
                <p className="text-themeColor text-right text-4xl font-bold">حدیث روز</p>
                <p className="text-themeColorDark mt-4 text-right text-[22px] font-bold mt-2">
                  {getPersianDayOfWeek(postDetail.hadith_date_post)}
                  {persianDate(postDetail.hadith_date_post)}
                </p>
              </div>
            </div>
          }
        />
      )}

      <ScrollToTop />
      <Footer />
    </div>
  );
}

export default HadithOfTheDay;

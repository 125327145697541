import { breadCrumbTree } from '../module/ConstantFunctions';
import { useNavigate } from 'react-router';

const ClickablePath = ({
  bookId,
  mainCategoryId,
  categoryId,
  subcategoryId,
  addSubCategoryId,
  //
  mainCategoryName,
  categoryName,
  subcategoryName,
  addSubcategoryName,
}) => {
  const navigate = useNavigate();
  const { parentCat, cat, subCat, addSubCat } = breadCrumbTree(
    mainCategoryName,
    categoryName,
    subcategoryName,
    addSubcategoryName
  );

  return (
    <>
      {parentCat ? (
        <>
          &nbsp; › &nbsp;
          <div
            className="inline cursor-pointer"
            onClick={() => {
              navigate(`/Chapters?bookId=${bookId}&mainCategory=${mainCategoryId}`);
            }}>
            {parentCat}
          </div>
        </>
      ) : null}
      {cat ? (
        <>
          &nbsp; › &nbsp;
          <div
            className="inline cursor-pointer"
            onClick={() => {
              navigate(`/Chapters?bookId=${bookId}&mainCategory=${mainCategoryId}&category=${categoryId}`);
            }}>
            {cat}
          </div>
        </>
      ) : null}
      {subCat ? (
        <>
          &nbsp; › &nbsp;
          <div
            className="inline cursor-pointer"
            onClick={() => {
              navigate(
                `/Chapters?bookId=${bookId}&mainCategory=${mainCategoryId}&category=${categoryId}&subCategory=${subcategoryId}`
              );
            }}>
            {subCat}
          </div>
        </>
      ) : null}
      {addSubCat ? (
        <>
          &nbsp; › &nbsp;
          <div
            className="inline cursor-pointer"
            onClick={() => {
              navigate(
                `/Chapters?bookId=${bookId}&mainCategory=${mainCategoryId}&category=${categoryId}&subCategory=${subcategoryId}&addSub_category=${addSubCategoryId}`
              );
            }}>
            {addSubCat}
          </div>
        </>
      ) : null}
    </>
  );
};

export default ClickablePath;

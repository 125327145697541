import './App.css';
import { BrowserRouter } from 'react-router-dom';
import { Routes, Route } from 'react-router';
import Home from './components/Home';
import Hadith from './components/hadith';
import Categories from './components/categories';
import Articles from './components/articles';
import Books from './components/Books';
import SingleBook from './components/SingleBook';
import HadithDetail from './components/HadithDetail';
import MostViewed from './components/MostViewed';
import Chapters from './components/Chapters';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { hadithOfTheDayAction, mostViewedHadithAction } from './module/action/Hadith';
import HadithOfTheDay from './components/HadithDetail/HadithOfTheDay';
import MostFavourite from './components/HadithDetail/MostFavourite';
import SearchHadith from './components/SearchHadith';
import SearchResult from './components/HadithDetail/SearchResult';
import OurIdentity from './components/our-identity';
import AboutUs from './components/about-us';
import CommentsAndSuggestions from './components/comments-and-suggestions';
import Characteristic from './components/characteristic';
import TermsAndConditions from './components/terms-and-conditions';
import ContactUs from './components/contact-us';

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(hadithOfTheDayAction(new Date()));
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" Component={Home} />
        <Route path="/hadith" Component={Hadith} />
        <Route path="/hadithOfTheDay" Component={HadithOfTheDay} />
        <Route path="/SearchResult" Component={SearchResult} />
        <Route path="/categories" Component={Categories} />
        <Route path="/articles" Component={Articles} />
        <Route path="/Books" Component={Books} />
        <Route path="/SingleBook" Component={SingleBook} />
        <Route path="/HadithDetail" Component={HadithDetail} />
        <Route path="/MostViewed" Component={MostViewed} />
        <Route path="/SearchHadith" Component={SearchHadith} />
        <Route path="/MostFavourite" Component={MostFavourite} />
        <Route path="/Chapters" Component={Chapters} />
        <Route path="/our-identity" Component={OurIdentity} />
        <Route path="/about-us" Component={AboutUs} />
        <Route path="/comments-and-suggestions" Component={CommentsAndSuggestions} />
        <Route path="/characteristic" Component={Characteristic} />
        <Route path="/terms-and-conditions" Component={TermsAndConditions} />
        <Route path="/contact-us" Component={ContactUs} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

import React, { useEffect, useState } from 'react';
import FacebookLogo from '../../assests/images/Facebooklogo.svg';
import Whatsapp from '../../assests/images/Whatsapp.svg';
import Twitter from '../../assests/images/twitter.svg';
import Mail from '../../assests/images/Mail.svg';
import Copy from '../../assests/images/Copy.svg';
import Download from '../../assests/images/download.svg';
import CopyWhite from '../../assests/images/copyWhite.svg';
import DownloadWhite from '../../assests/images/downloadWhite.svg';
import Play from '../../assests/images/play.svg';
import pause from '../../assests/images/pause.png';
import Tooltip from '../Tooltip';
import { catTree, copyFunction, downloadFile, lineBreak, printElem } from '../../module/ConstantFunctions';
import { assetURL } from '../../api/utils';
import ButtonSpinner from '../ButtonSpinner';
import { EmailShareButton, FacebookShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';
import axios from 'axios';
import { printElement } from '../../module/printPage';
import download from 'downloadjs';
import { downloadPost } from '../../module/action/Post';
import { useDispatch } from 'react-redux';

import { swapNumbersInString } from '../../util';

function Card({ background, onClick, heading, data, index, chapter, shareUrl }) {
  const dispatch = useDispatch();
  const [CopyHover, setCopyHover] = useState(false);
  const [DownloadHover, setDownloadHover] = useState(false);
  const [PlayHover, setPlayHover] = useState(false);
  const [played, setPlayed] = useState(false);
  let [downloadLoader, setDownloadLoader] = useState(false);

  const playSound = play => {
    setPlayed(play);
  };

  function playAudio(e) {
    e.stopPropagation();
    if (data.recitation_audio || data.recitation_audio_url) {
      setPlayed(true);
      var x = document.getElementById('myAudio');
      x.play();
    }
  }

  function pauseAudio(e) {
    if (data.recitation_audio || data.recitation_audio_url) {
      e.stopPropagation();
      setPlayed(false);
      var x = document.getElementById('myAudio');
      x.pause();
    }
  }

  const print = objParam => {
    //  (objParam);
    dispatch(downloadPost(objParam, setDownloadLoader));
  };

  data = {
    ...data,
    post_text: swapNumbersInString(data?.post_text || ''),
    post_number: swapNumbersInString(data?.post_number || ''),
    reference: swapNumbersInString(data?.reference || ''),
  };
  return data ? (
    <div
      key={index}
      className={`${onClick ? 'cursor-pointer' : 'cursor-auto'} w-full md:container ${
        heading ? 'mb-0' : 'mb-8'
      } mx-auto 
          ${index % 2 ? ` bg-[#F9F9F9]` : `border-[#2A9134] bg-green-50`}
           text-right rounded-xl border p-8 shadow-lg`}>
      <div onClick={onClick} className="text-2xl leading-10 font-bold mb-5 flex justify-end">
        <p className="text-right line-clamp-3 text-text-ellipsis" dir="rtl">
          <span dangerouslySetInnerHTML={{ __html: `(${data.post_number}) ${data.post_text}` }} />
        </p>
      </div>
      <p onClick={onClick} className="text-themeColorDark mb-3 font-bold text-lg" dir="rtl" id={`${chapter}-${index}`}>
        <span dangerouslySetInnerHTML={{ __html: data.reference }} />

        {/* {`[${data.reference}]`} */}
      </p>
      <p onClick={onClick} className="text-themeColorDark mb-3 font-bold text-base" dir="rtl">
        {catTree(data).tree}
      </p>
      {/* card footer */}
      <div className="flex flex-col md:flex-row justify-between items-end md:items-center">
        <div className="mb-5 md:mb-0 flex items-center">
          <button
            onMouseOver={() => setCopyHover(true)}
            onMouseOut={() => setCopyHover(false)}
            className="relative hover:bg-[#0E3A1E] border-[#0E3A1E3D] border-[2px] ease-in duration-300  bg-white rounded-full border-2 p-2 m-1"
            onClick={e => {
              copyFunction(data.post_text, e, `${chapter}-${index}`);
            }}>
            {CopyHover && <Tooltip text={'کپی'} />}
            {CopyHover ? <img src={CopyWhite} width={'24px'} /> : <img src={Copy} width={'24px'} />}
          </button>

          <button
            disabled={downloadLoader}
            onMouseOver={() => setDownloadHover(true)}
            onMouseOut={() => setDownloadHover(false)}
            className="relative hover:bg-[#0E3A1E] border-[#0E3A1E3D] border-[2px] ease-in duration-300 bg-white rounded-full border-2 p-2 m-1 flex items-center justify-center"
            onClick={e => {
              e.stopPropagation();
              print(data);
            }}>
            {downloadLoader ? <ButtonSpinner color={'#0E3A1E'} /> : null}

            <span
              className={
                downloadLoader ? `opacity-0 flex items-center justify-center` : 'flex items-center justify-center'
              }>
              {DownloadHover && <Tooltip text={'دانلود'} />}
              {DownloadHover ? <img src={DownloadWhite} width={'24px'} /> : <img src={Download} width={'24px'} />}
            </span>
          </button>
          {data.recitation_audio_url || data.recitation_audio ? (
            <>
              <audio id="myAudio">
                <source
                  src={data.recitation_audio_url ? data.recitation_audio_url : `${assetURL}${data.recitation_audio}`}
                  type="audio/mp3"
                />
                <source
                  src={data.recitation_audio_url ? data.recitation_audio_url : `${assetURL}${data.recitation_audio}`}
                  type="audio/mp3"
                />
                Your browser does not support the audio element.
              </audio>

              {played ? (
                <button
                  onMouseOver={() => setPlayHover(true)}
                  onMouseOut={() => setPlayHover(false)}
                  className="relative hover:bg-[#0E3A1E] border-[#2A9134] border-[2px] ease-in duration-300 bg-[#2A9134] rounded-full border p-2 m-1"
                  onClick={e => pauseAudio(e)}>
                  {/* <Tooltip text={'بازخوانی'}/> */}
                  {PlayHover && <Tooltip text={'بازخوانی'} />}
                  <img src={pause} width={'24px'} />
                </button>
              ) : (
                (data.recitation_audio_url || data.recitation_audio) && (
                  <button
                    onMouseOver={() => setPlayHover(true)}
                    onMouseOut={() => setPlayHover(false)}
                    className="relative hover:bg-[#0E3A1E] border-[#2A9134] border-[2px] ease-in duration-300 bg-[#2A9134] rounded-full border p-2 m-1"
                    onClick={e => playAudio(e)}>
                    {/* <Tooltip text={'بازخوانی'}/> */}
                    {PlayHover && <Tooltip text={'بازخوانی'} />}
                    <img src={Play} width={'24px'} />
                  </button>
                )
              )}
            </>
          ) : null}
        </div>
        <div className="flex items-center">
          <div>
            {/* <EmailShareButton url={shareUrl}> */}
            <button
              onClick={e => {
                e.stopPropagation();
                window.open(
                  shareUrl,
                  'Send Via Email',
                  'menubar=1,resizable=1,width=800,height=650, top=250, left=250'
                );
              }}
              className="bg-white rounded-full border p-2 m-1 hover:bg-[#0E3A1E] border-[#0E3A1E3D] ease-in duration-300 [&>*]:hover:invert-[1] [&>*]:hover:contrast-200">
              <img src={Mail} width={'22px'} />
            </button>
            {/* </EmailShareButton> */}
            <WhatsappShareButton url={shareUrl}>
              <button className="bg-white rounded-full border p-2 m-1 hover:bg-[#0E3A1E] border-[#0E3A1E3D] ease-in duration-300 [&>*]:hover:invert-[1] [&>*]:hover:contrast-200">
                <img src={Whatsapp} width={'22px'} />
              </button>
            </WhatsappShareButton>
            <TwitterShareButton url={shareUrl}>
              <button className="bg-white rounded-full border p-2 m-1 hover:bg-[#0E3A1E] border-[#0E3A1E3D] ease-in duration-300 [&>*]:hover:invert-[1] [&>*]:hover:contrast-200">
                <img src={Twitter} width={'22px'} />
              </button>
            </TwitterShareButton>
            <FacebookShareButton url={shareUrl}>
              <button className="bg-white rounded-full border p-2 m-1 hover:bg-[#0E3A1E] border-[#0E3A1E3D] ease-in duration-300 ">
                <img src={FacebookLogo} width={'22px'} />
              </button>
            </FacebookShareButton>
          </div>
          <p className="ml-2 text-base font-bold">: مشارکت</p>
        </div>
      </div>
    </div>
  ) : null;
}

export default Card;

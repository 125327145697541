import React, { useEffect, useState } from 'react';
import './index.css';
import FacebookLogo from '../../assests/images/Facebooklogo.svg';
import Instagram from '../../assests/images/instagram.svg';
import Twitter from '../../assests/images/twitter.svg';
import Logo from '../../assests/images/Logo.svg';
import Searchbar from './searchbar';
import LogoText from '../../assests/images/logoTxt.png';
import Menu from '../../assests/images/Menu.png';
import PolygonArrow from '../../assests/images/PolygonArrow.png';
import DownArrowWhite from '../../assests/images/DownArrowWhite.png';
import { useLocation, useNavigate } from 'react-router';
import Drawer from '../Drawer';
import { getLatestNewsAction, searchPostAction } from '../../module/action/Post';
import { useDispatch, useSelector } from 'react-redux';
function Header({ heading, limit, page, text }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const getLatestNewsData = useSelector(e => e.Post.getLatestNewsData);

  const [showDrawer, setShowDrawer] = useState(false);
  const [dropdownHover, setdropdownHover] = useState(0);
  const [newsPost, setNewsPost] = useState('');
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    dispatch(getLatestNewsAction());
  }, []);

  useEffect(() => {
    if (getLatestNewsData.length) {
      let news = '';
      getLatestNewsData.map(a => {
        news = news + a.title_name + '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0';
      });
      setNewsPost(news);
    }
  }, [getLatestNewsData]);

  const searchPost = ev => {
    const navigateFunc = () => {
      navigate(`/SearchHadith?text=${ev.target.value}`);
    };
    dispatch(searchPostAction(ev.target.value, 0, limit, navigateFunc));
  };
  return (
    <div
      className={`${
        heading
          ? 'bg-mobfullHeaderBg md:bg-fullHeaderBg headerContainer'
          : 'bg-cover pb-3 md:pb-10 bg-mobEmptyHeaderBg md:bg-emptyHeaderBg '
      }  bg-repeat-space md:bg-no-repeat`}>
      {/* news slider */}
      <marquee direction="right" className="text-white text-lg bg-[#0D4001] py-1.5">
        {newsPost}
      </marquee>
      {/* menu */}
      <div className="md:container px-4 md:px-0 mt-5 mx-auto hidden md:flex justify-between">
        <div>
          <button className="bg-white opacity-80 rounded-md p-1.5 m-1.5">
            <img src={Instagram} className="w-5" />
          </button>
          <button className="bg-white opacity-80 rounded-md p-1.5 m-1.5">
            <img src={Twitter} className="w-5" />
          </button>
          <button className="bg-white rounded-md p-1.5 m-1.5">
            <img src={FacebookLogo} className="w-5" />
          </button>
        </div>
        <div>
          {/* <div
            style={{
              width: "612px",
              display: "flex",
              justifyContent: "space-between",
            }}
          > */}
          <button className="text-white text-2xl mx-2" onClick={() => navigate('/comments-and-suggestions')}>
            نظرات و پیشنهادات
          </button>
          <button className="text-white text-2xl mx-2" onClick={() => navigate('/about-us')}>
            در باره این سایت
          </button>
          <button className="text-white text-2xl mx-2" onClick={() => navigate('/our-identity')}>
            هویت ما
          </button>
          <button className="relative pb-[30px] text-white text-2xl mx-2 dropdown ">
            محتویات
            <img src={DownArrowWhite} className="absolute top-3/4 left-1/3  dropdownArrow" />
            <div className="dropdown-content shadow bg-white rounded-md ">
              <img src={PolygonArrow} className="polygonArrow" />
              <div
                onClick={() => navigate('/Books')}
                onMouseOver={() => setdropdownHover(1)}
                onMouseOut={() => setdropdownHover(0)}
                className={
                  location.pathname == '/Books' ||
                  location.pathname == '/SingleBook' ||
                  location.pathname == '/categories' ||
                  location.pathname == '/Chapters' ||
                  location.pathname == '/HadithDetail' ||
                  dropdownHover === 1
                    ? 'border-green-900 bg-green-50 text-themeColor w-full text-xl py-2 font-bold rounded-md'
                    : 'bg-gray-50 text-black w-full text-xl py-2 font-bold rounded-md'
                }>
                کتاب ها
              </div>
              <div
                onClick={() => navigate('/articles')}
                onMouseOver={() => setdropdownHover(2)}
                onMouseOut={() => setdropdownHover(0)}
                className={
                  location.pathname === '/articles' || dropdownHover === 2
                    ? 'border-green-900 mt-3 bg-green-50 text-themeColor w-full text-xl py-2 font-bold rounded-md'
                    : 'bg-gray-50 mt-3 text-black w-full text-xl py-2 font-bold rounded-md'
                }>
                مقالات{' '}
              </div>
            </div>
          </button>
          <button onClick={() => navigate('/')} className="text-white text-2xl mx-2">
            صفحه اصلی
          </button>
          {/* </div> */}
          <Searchbar onChange={searchPost} text={text} searchText={searchText} setSearchText={setSearchText} />
        </div>
        <div>
          <img onClick={() => navigate('/')} className="cursor-pointer" src={Logo} />
        </div>
      </div>
      <div className="md:container px-4 md:px-16 mt-5 mx-auto md:hidden flex justify-between items-center">
        <div className="w-64 flex justify-end">
          <img onClick={() => navigate('/')} src={Logo} className="cursor-pointer ml-[116px]" />
        </div>
        <div className="w-24 grow md:hidden text-right">
          <button onClick={() => setShowDrawer(true)} className="bg-white rounded-full border p-3 m-1 ">
            <img src={Menu} width={24} />
          </button>
        </div>
      </div>
      {/* searchbar */}
      <div className="block md:hidden">
        <Searchbar onChange={searchPost} text={text} searchText={searchText} setSearchText={setSearchText} />
      </div>
      <Drawer width={showDrawer ? '100%' : '0%'} setShowDrawer={setShowDrawer} />
      {/* description text */}

      {heading && (
        <div className="md:w-[38rem] px-6 md:px-8 py-3 text-center mb-16 mx-auto">
          <img src={LogoText} className="mx-auto mb-15" style={{ width: '245px' }} />
          <p className="text-white tex-center text-2xl mt-[28px] leading-10" dir="rtl">
            <span>به این مجموعه که شامل تعالیم الهی، احادیث نبوی،</span>
            <br className="hidden md:block" /> <span> تالیفات، مقالات و روش های ایدئال زندگی در راستای گسستن </span>
            <br className="hidden md:block" />
            <span>زنجیرهای بردگی و آزاد منشی در دنیا و نجات در آخرت است خوش آمدید</span>
          </p>
        </div>
      )}
    </div>
  );
}

export default Header;

import { numberToArabic } from 'number-to-arabic';
import React, { useEffect, useState } from 'react';
import RightArrow from '../../assests/images/RightArrow.png';
import LeftArrow from '../../assests/images/LeftArrowWhite.png';
function Pagination({ page, setPage, limit, count }) {
  const [activeIndex, setActiveIndex] = useState(1);
  const [pageArr, setPageArr] = useState([]);
  let active = 'py-2 px-5 rounded-lg text-2xl font-bold text-white mr-5 bg-[#2A9134]';
  let nonActive = 'py-2 px-5 rounded-lg text-2xl font-bold mr-5 bg-[#ECECEC]';

  // useEffect(() => {
  //   setPage(activeIndex - 1);
  // }, [activeIndex]);

  useEffect(() => {
    let number = Math.ceil(+count / +limit);
    let arr = Array.from(Array(number).keys());
    setPageArr(arr);
  }, [count, limit]);

  return (
    <>
      {pageArr.length ? (
        <div dir={'rtl'} className="container mx-auto p-8  justify-center flex">
          {page === 0 ? null : (
            <button
              // key={i}
              onClick={() => {
                // setActiveIndex(i + 1);
                setPage(page - 1);
              }}
              className={nonActive}>
              <img src={RightArrow} />
            </button>
          )}
          {pageArr.length - 1 === page ? null : (
            <button
              // key={i}
              onClick={() => {
                // setActiveIndex(i + 1);
                setPage(page + 1);
              }}
              className={nonActive}>
              <img src={RightArrow} className="rotate-180" />
            </button>
          )}

          {/* {pageArr.map((a, i) => {
        return (
          <button key={i} onClick={() => {
            setActiveIndex(i + 1)
            setPage(i)
            }} className={i === page ? active : nonActive}>
            {numberToArabic(i + 1)}
          </button>
        );
      })} */}
          {/* <button onClick={() => setActiveIndex(2)} className={activeIndex === 2 ? active : nonActive}>
        ٢
      </button>
      <button onClick={() => setActiveIndex(3)} className={activeIndex === 3 ? active : nonActive}>
        ٣
      </button> */}
        </div>
      ) : null}
    </>
  );
}

export default Pagination;

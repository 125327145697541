import React, { useEffect, useState } from 'react';
import Header from '../../constant/Header';
import Footer from '../../constant/footer';
import DownArrow from '../../assests/images/downarrow.png';
import LeftArrow from '../../assests/images/leftArrow.png';
import { useNavigate } from 'react-router';
import ScrollToTop from '../../constant/ScrollToTop';
import { useDispatch, useSelector } from 'react-redux';
import DropDown from '../../constant/dropdown';
import { getCategoryAction } from '../../module/action/Category';
import { numberToArabic } from 'number-to-arabic';
import { Tree } from 'antd';
import Spinner from '../../constant/Spinner';
import { assetURL } from '../../api/utils';
import { getAllBookAction, getBookVolumesAction, getSingleBookAction } from '../../module/action/Book';
import PolygonArrow from '../../assests/images/PolygonArrow.png';

const { DirectoryTree } = Tree;
function CategoryDataComp({ val, i, CategoryData }) {
  const navigate = useNavigate();

  const subCategoryData = useSelector(e => e.Category.subCategoryData);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    if (subCategoryData.length) {
      let arr = [];
      subCategoryData.map((a, i) => {
        if (a.refId === val._id) {
          let cat = [];
          subCategoryData.map((b, j) => {
            if (b.refId === a._id) {
              let subCat = [];
              subCategoryData.map((c, k) => {
                if (c.refId === b._id) {
                  let addSubCat = [];

                  subCat.push({
                    ...c,
                    title: (
                      <p
                        onClick={() => {
                          let obj = c;
                          navigate(
                            `/Chapters?bookId=${val.bookId}&mainCategory=${val._id}&category=${a._id}&subCategory=${b._id}&addSub_category=${c._id}`
                          );
                        }}
                        className="font-naskh-arabic text-2xl font-bold text-[#5D5D5D] hover:text-black mb-1 flex justify-between items-center leading-10 [&>*]:opacity-0 [&>*]:hover:opacity-100">
                        {c.category_name}
                        <img
                          className="absolute left-0"
                          src={LeftArrow}
                          style={{ height: '16px', cursor: 'pointer' }}
                        />
                      </p>
                    ),
                    key: `${i}-${j}-${k}`,
                  });
                }
              });
              cat.push({
                ...b,
                title: (
                  <p
                    onClick={() => {
                      let obj = b;
                      if (subCat.length === 0) {
                        navigate(
                          `/Chapters?bookId=${val.bookId}&mainCategory=${val._id}&category=${a._id}&subCategory=${b._id}`
                        );
                      }
                    }}
                    className="font-naskh-arabic text-2xl font-bold text-[#5D5D5D] hover:text-black mb-1 flex justify-between items-center leading-10 [&>*]:opacity-0 [&>*]:hover:opacity-100">
                    {b.category_name}
                    <img className="absolute left-0" src={LeftArrow} style={{ height: '16px', cursor: 'pointer' }} />
                  </p>
                ),
                key: `${i}-${j}`,
                categories: subCat.sort((a, b) => a.id - b.id),
                children: subCat.sort((a, b) => a.id - b.id),
              });
            }
          });
          arr.push({
            ...a,
            title: (
              <p
                onClick={() => {
                  let obj = a;
                  if (cat.length === 0) {
                    navigate(`/Chapters?bookId=${val.bookId}&mainCategory=${val._id}&category=${a._id}`);
                  }
                }}
                className="font-naskh-arabic text-2xl font-bold text-[#5D5D5D] hover:text-black mb-1 flex justify-between items-center leading-10 [&>*]:opacity-0 [&>*]:hover:opacity-100">
                {a.category_name}
                <img className="absolute left-0" src={LeftArrow} style={{ height: '16px', cursor: 'pointer' }} />
              </p>
            ),
            key: `${i}`,
            categories: cat.sort((a, b) => a.id - b.id),
            children: cat.sort((a, b) => a.id - b.id),
          });
        }
      });
      setCategories(arr.sort((a, b) => a.id - b.id));
    }
  }, [subCategoryData, val]);

  return (
    <div className="w-11/12 md:w-[350px] md:mr-0 mx-auto md:ml-4" key={i}>
      <div className="mb-3 border border-[#2A9134] bg-green-50 rounded-lg flex items-center py-4 px-5 text-right">
        <p className="text-3xl ml-5 font-bold w-[15%]">
          {numberToArabic(i + 1)} / {numberToArabic(CategoryData.length)}
        </p>
        <div className="w-[85%]">
          <p
            className="text-4xl text-themeColor font-bold cursor-pointer "
            onClick={() => {
              navigate(`/Chapters?bookId=${val.bookId}&mainCategory=${val._id}`);
            }}>
            {val.category_name}
          </p>
          <p className="text-2xl text-[#5D5D5D] ">{val.chapterName}</p>
        </div>
      </div>
      <div className="mb-6 border border-[#2A9134] rounded-lg py-4 px-5 text-right">
        <DirectoryTree treeData={categories} />

        <p className="text-2xl font-bold mb-1 flex justify-between items-center leading-10">
          نمایش تمام احادیث
          <img
            src={LeftArrow}
            onClick={() => navigate(`/Chapters?bookId=${val.bookId}&mainCategory=${val._id}`)}
            style={{ width: '20px', height: '16px', cursor: 'pointer' }}
          />
        </p>
      </div>
    </div>
  );
}

function Categories() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const CategoryData = useSelector(e => e.Category.CategoryData);
  const getCategoryLoader = useSelector(e => e.Category.getCategoryLoader);
  const getSingleBookLoader = useSelector(e => e.Book.getSingleBookLoader);
  const getSingleBook = useSelector(e => e.Book.getSingleBook);
  const bookDataRedux = useSelector(e => e.Book.bookData);
  const [bookData, setBookData] = useState({});
  const [mainCategory, setMainCategory] = useState([]);
  const volumesData = useSelector(e => e.Book.volumesData);
  const getBookVolumesLoader = useSelector(e => e.Book.getBookVolumesLoader);
  const [volume, setVolume] = useState({ volume_name: 'انتخاب جلد' });

  useEffect(() => {
    const url = new URL(window.location);
    const bookId = url.searchParams.get('bookId');

    if (bookId) {
      dispatch(getSingleBookAction(bookId));
      dispatch(getCategoryAction(bookId, 'Main'));
      dispatch(getCategoryAction(bookId, 'Sub'));
      dispatch(getBookVolumesAction(bookId));
    }
    if (!bookDataRedux.length) {
      dispatch(getAllBookAction());
    }
  }, []);
  useEffect(() => {
    if (CategoryData) {
      setMainCategory(CategoryData);
    }
  }, [CategoryData]);
  useEffect(() => {
    setBookData(getSingleBook);
  }, [getSingleBook]);

  const bookSelect = obj => {
    dispatch(getSingleBookAction(obj._id));
    dispatch(getCategoryAction(obj._id, 'Main'));
    dispatch(getCategoryAction(obj._id, 'Sub'));
    dispatch(getBookVolumesAction(obj._id));
    setVolume({ volume_name: 'انتخاب جلد' });
    // navigate(`/categories?bookId=${obj._id}`)
  };
  const volumeSelect = obj => {
    setVolume(obj);
  };
  const [dropdownActive, setDropDownActive] = useState(false);
  return (
    <div className="relative h-min-100">
      <Header page={0} limit={2} />
      <div>
        <img
          src={bookData.logo_img_url ? bookData.logo_img_url : `${assetURL}${bookData.logo_img}`}
          // src={CatregoryHeading}
          className="mx-auto mt-10 w-[300px]  md:w-[372px] h-[125px] object-contain"
        />
        <div className="container   mx-auto p-4 pb-4 md:pb-9 md:px-0 mt-10 flex flex-col-reverse md:flex-row justify-between items-center">
          <div className="flex flex-col-reverse md:flex-row w-full md:w-auto">
            <button
              className={`relative dropdown2 border mb-5 md:mb-0 rounded-[16px] w-full md:w-[256px] px-5  py-2 md:mr-4 text-xl font-bold flex items-center justify-between ${
                dropdownActive ? 'active' : ''
              }`}
              onClick={() => setDropDownActive(!dropdownActive)}
              onBlur={() => setDropDownActive(false)}>
              <img src={DownArrow} className="up-arrow-drop2" />
              <img src={DownArrow} className="down-arrow-drop2" />
              <div className="line-clamp-1 text-ellipsis " dir="rtl">
                {bookData.bookName}
              </div>
              <div className="dropdown-content2 shadow bg-white rounded-md max-h-[230px] overflow-auto">
                <img src={PolygonArrow} className="polygonArrow" />
                {bookDataRedux &&
                  bookDataRedux.map((a, i) => {
                    return a.status === 'approved' ? (
                      <div
                        key={i}
                        className={'bg-gray-50 mb-3 text-black w-full text-xl py-2 font-bold rounded-md '}
                        onClick={() => bookSelect(a)}>
                        {a.bookName}
                      </div>
                    ) : null;
                  })}
              </div>
            </button>
          </div>
          <p className="w-full md:w-auto text-themeColor text-right text-4xl font-bold mb-5 md:mb-0">فهرست</p>
        </div>
        <div className="flex flex-wrap container mx-auto md:px-0" dir="rtl">
          {getCategoryLoader ? (
            <Spinner />
          ) : (
            mainCategory.map((val, i) => <CategoryDataComp val={val} i={i} CategoryData={CategoryData} />)
          )}
        </div>
      </div>
      <ScrollToTop />
      <Footer />
    </div>
  );
}

export default Categories;

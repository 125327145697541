import { combineReducers } from 'redux';
import Book from './store/Book';
import Category from './store/Category';
import Articles from './store/Articles';
import Post from './store/Post';
import Hadith from './store/Hadith';

export default combineReducers({
  Book: Book,
  Category: Category,
  Articles: Articles,
  Post: Post,
  Hadith: Hadith,
});

import React, { useState } from 'react';
import upArrow from '../../assests/images/upArrow.png';

function ScrollToTop() {
  // const [visible, setVisible] = useState(false);

  // const toggleVisible = () => {
  //   const scrolled = document.documentElement.scrollTop;
  //   if (scrolled > 300 && window.innerWidth > 765) {
  //     setVisible(true);
  //   } else if (scrolled <= 300) {
  //     setVisible(false);
  //   }
  // };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
      /* you can also use 'auto' behaviour
           in place of 'smooth' */
    });
  };

  // window.addEventListener("scroll", toggleVisible);
  return (
    <button
      className="scrollTopBtn bg-[#EEF6EF] md:block hidden py-2 px-2.5 rounded-[16px] border border-[#2A9134] absolute w-[56px] h-[56px]"
      onClick={scrollToTop}>
      <img src={upArrow} />
    </button>
  );
}

export default ScrollToTop;

import axios from 'axios';
import { dateFormatHadith } from '../ConstantFunctions';

export const HADITH_OF_THE_DAY_LOADER = 'HADITH_OF_THE_DAY_LOADER';
export const hadithOfTheDayLoader = payload => ({
  type: 'HADITH_OF_THE_DAY_LOADER',
  payload: payload,
});

export const hadithOfTheDayAction = today => {
  return async dispatch => {
    dispatch(hadithOfTheDayLoader(true));
    // setDeleteLoader(true)

    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `/api/v1/admin/allPost/hadithget?${today ? `today=${dateFormatHadith(today)}` : ''}`,
      headers: {},
    };

    axios(config)
      .then(response => {
        dispatch(hadithOfTheDayData(response.data.datas.todaydata));
        dispatch(hadithOfTheDayLoader(false));
      })
      .catch(error => {
        // setDeleteLoader(false)
        dispatch(hadithOfTheDayLoader(false));
      });
  };
};

export const HADITH_OF_THE_DAY_DATA = 'HADITH_OF_THE_DAY_DATA';
export const hadithOfTheDayData = payload => ({
  type: 'HADITH_OF_THE_DAY_DATA',
  payload: payload,
});

export const PREVIOUS_HADITH_OF_THE_DAY_LOADER = 'PREVIOUS_HADITH_OF_THE_DAY_LOADER';
export const previousHadithOfTheDayLoader = payload => ({
  type: 'PREVIOUS_HADITH_OF_THE_DAY_LOADER',
  payload: payload,
});

export const previousHadithOfTheDayAction = (page, limit) => {
  return async dispatch => {
    dispatch(previousHadithOfTheDayLoader(true));
    // setDeleteLoader(true)

    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `/api/v1/admin/allPost/getdailyhadithlist?page=${page}&limit=${limit}`,
      headers: {},
    };

    axios(config)
      .then(response => {
        dispatch(previousHadithOfTheDayData(response.data.data));
        dispatch(previousHadithCount(response.data.count));
        dispatch(previousHadithOfTheDayLoader(false));
      })
      .catch(error => {
        // setDeleteLoader(false)
        dispatch(previousHadithOfTheDayLoader(false));
      });
  };
};

export const PREVIOUS_HADITH_OF_THE_DAY_DATA = 'PREVIOUS_HADITH_OF_THE_DAY_DATA';
export const previousHadithOfTheDayData = payload => ({
  type: 'PREVIOUS_HADITH_OF_THE_DAY_DATA',
  payload: payload,
});

export const PREVIOUS_HADITH_COUNT = 'PREVIOUS_HADITH_COUNT';
export const previousHadithCount = payload => ({
  type: 'PREVIOUS_HADITH_COUNT',
  payload: payload,
});

export const MOST_VIEWED_HADITH_LOADER = 'MOST_VIEWED_HADITH_LOADER';
export const mostViewedHadithLoader = payload => ({
  type: 'MOST_VIEWED_HADITH_LOADER',
  payload: payload,
});

export const mostViewedHadithAction = (page, limit) => {
  return async dispatch => {
    dispatch(mostViewedHadithLoader(true));
    // setDeleteLoader(true)

    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `/api/v1/admin/allPost/getMostViewedHaidth?page=${page}&limit=${limit}`,
      headers: {},
    };

    axios(config)
      .then(response => {
        dispatch(mostViewedHadithData(response.data.data));
        dispatch(mostViewedHadithDataCount(response.data.count));
        dispatch(mostViewedHadithLoader(false));
      })
      .catch(error => {
        // setDeleteLoader(false)
        dispatch(mostViewedHadithLoader(false));
      });
  };
};

export const MOST_VIEWED_HADITH_DATA = 'MOST_VIEWED_HADITH_DATA';
export const mostViewedHadithData = payload => ({
  type: 'MOST_VIEWED_HADITH_DATA',
  payload: payload,
});
export const MOST_VIEWED_HADITH_DATA_COUNT = 'MOST_VIEWED_HADITH_DATA_COUNT';
export const mostViewedHadithDataCount = payload => ({
  type: 'MOST_VIEWED_HADITH_DATA_COUNT',
  payload: payload,
});

export const GET_SINGLE_VIEWED_LOADER = 'GET_SINGLE_VIEWED_LOADER';
export const getSingleMostViewedHadithLoader = payload => ({
  type: 'GET_SINGLE_VIEWED_LOADER',
  payload: payload,
});

export const getSingleMostViewedHadithAction = id => {
  return async dispatch => {
    dispatch(getSingleMostViewedHadithLoader(true));

    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `/api/v1/admin/allPost/getSingleFavouriteForWeb/${id}`,
      headers: {},
    };

    axios(config)
      .then(response => {
        dispatch(getSingleMostViewedHadithData(response.data.data));
        dispatch(
          getSingleMostViewedHadithNavigate({
            next: response.data.next,
            previous: response.data.previous,
          })
        );
        dispatch(getSingleMostViewedHadithLoader(false));
      })
      .catch(error => {
        dispatch(getSingleMostViewedHadithLoader(false));
      });
  };
};

export const SINGLE_POST_DATA_DATA = 'SINGLE_POST_DATA_DATA';
export const getSingleMostViewedHadithData = payload => ({
  type: 'SINGLE_POST_DATA_DATA',
  payload: payload,
});

export const SINGLE_POST_DATA_NAVIAGTE = 'SINGLE_POST_DATA_NAVIAGTE';
export const getSingleMostViewedHadithNavigate = payload => ({
  type: 'SINGLE_POST_DATA_NAVIAGTE',
  payload: payload,
});

export const GET_SEARCHED_HADITH_LOADER = 'GET_SEARCHED_HADITH_LOADER';
export const getSingleSearchHadithLoader = payload => ({
  type: 'GET_SEARCHED_HADITH_LOADER',
  payload: payload,
});

export const getSingleSearchHadithAction = (id, text) => {
  return async dispatch => {
    dispatch(getSingleSearchHadithLoader(true));

    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `/api/v1/admin/allPost/getSingleFavouriteForWeb/${id}?text=${text}`,
      headers: {},
    };

    axios(config)
      .then(response => {
        dispatch(getSingleSearchHadithData(response.data.data));
        dispatch(
          getSingleSearchHadithNavigate({
            next: response.data.next,
            previous: response.data.previous,
          })
        );
        dispatch(getSingleSearchHadithLoader(false));
      })
      .catch(error => {
        dispatch(getSingleSearchHadithLoader(false));
      });
  };
};

export const SINGLE_SEARCH_POST_DATA_DATA = 'SINGLE_SEARCH_POST_DATA_DATA';
export const getSingleSearchHadithData = payload => ({
  type: 'SINGLE_SEARCH_POST_DATA_DATA',
  payload: payload,
});

export const SINGLE_SEARCH_POST_DATA_NAVIAGTE = 'SINGLE_SEARCH_POST_DATA_NAVIAGTE';
export const getSingleSearchHadithNavigate = payload => ({
  type: 'SINGLE_SEARCH_POST_DATA_NAVIAGTE',
  payload: payload,
});

import axios from 'axios';

export const GET_CATEGORY_LOADER = 'GET_CATEGORY_LOADER';
export const getCategoryLoader = bool => ({
  type: 'GET_CATEGORY_LOADER',
  payload: bool,
});

export const getCategoryAction = (bookId, type) => {
  return async dispatch => {
    dispatch(getCategoryLoader(true));

    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `/api/v1/admin/categories/get?${bookId ? `bookId=${bookId}&` : ''}typeCategory=${type}`,
      headers: {
        'content-type': 'application/json',
      },
    };

    axios
      .request(config)
      .then(response => {
        //   clearStates()
        if (type === 'Main') {
          dispatch(CategoryData(response.data.data));
        } else if (type === 'Sub') {
          dispatch(subCategoryData(response.data.data));
        }
        dispatch(getCategoryLoader(false));
      })
      .catch(error => {
        dispatch(getCategoryLoader(false));
      });
  };
};

export const CATEGORY_DATA = 'CATEGORY_DATA';
export const CategoryData = payload => ({
  type: 'CATEGORY_DATA',
  payload: payload,
});

export const SUB_CATEGORY_DATA = 'SUB_CATEGORY_DATA';
export const subCategoryData = payload => ({
  type: 'SUB_CATEGORY_DATA',
  payload: payload,
});

export const GET_CATEGORIES_POST_LOADER = 'GET_CATEGORIES_POST_LOADER';
export const getCategoriesPostLoader = bool => ({
  type: 'GET_CATEGORIES_POST_LOADER',
  payload: bool,
});

export const getCategoriesPostAction = (bookId, refId) => {
  return async dispatch => {
    dispatch(getCategoriesPostLoader(true));

    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `/api/v1/admin/categories/getCategories?${refId ? `refId=${refId}&` : ''}${
        bookId ? `bookId=${bookId}` : ''
      }`,
      headers: {
        'content-type': 'application/json',
      },
    };

    axios
      .request(config)
      .then(response => {
        //   clearStates()
        dispatch(getCategoriesPostData(response.data.data));
        // dispatch(CategoryData(response.data.data))

        dispatch(getCategoriesPostLoader(false));
      })
      .catch(error => {
        dispatch(getCategoriesPostLoader(false));
      });
  };
};

export const GET_CATEGORIES_POST = 'GET_CATEGORIES_POST';
export const getCategoriesPostData = payload => ({
  type: 'GET_CATEGORIES_POST',
  payload: payload,
});

import React, { useState } from 'react';
import Arrow from '../../assests/images/Arrow.svg';
import Logo from '../../assests/images/Logo.svg';
import Close from '../../assests/images/Close.png';
import FacebookLogo from '../../assests/images/Facebooklogo.svg';
import Instagram from '../../assests/images/instagram.svg';
import Twitter from '../../assests/images/twitter.svg';
import './index.css';
import { useNavigate } from 'react-router';
function Drawer({ width, setShowDrawer }) {
  const navigate = useNavigate();
  const [ShowOption, setShowOption] = useState(true);
  return (
    <div id="myNav" className="overlay bg-fullBg" style={{ width }}>
      {/* <a href="javascript:void(0)" class="closebtn" onclick="closeNav()">
        &times;
      </a> */}
      <marquee className="text-white text-lg">
        لورم ایپسوم یا طرح‌نما به متنی آزمایشی و بی‌معنی در صنعت چاپ، صفحه ‌آرایی و طراحی گرافیک گفته می‌شود. طراح
        گرافیک از این متن به عنوان عنصری از ترکیب بندی برای پر کردن صفحه و ارایه اولیه شکل ظاهری و کلی طرح سفارش گرفته
        شده استفاده می نماید، تا از نظر گرافیکی نشانگر چگونگی نوع و اندازه فونت و ظاهر متن باشد.
      </marquee>
      <div className="md:container px-4 md:px-0 mt-5 mx-auto md:hidden flex justify-between items-center">
        <div className="w-64">
          <img onClick={() => navigate('/')} src={Logo} className="cursor-pointer ml-auto" />
        </div>
        <div className="w-24 grow md:hidden text-right">
          <button
            onClick={() => setShowDrawer(false)}
            className=" rounded-full  border-2 border-white bg-[#40902F] p-3 m-1 ">
            <img src={Close} />
          </button>
        </div>
      </div>
      <div className="container mx-auto p-6 mt-5">
        <button className="block w-full bg-white rounded-xl mb-4 py-2.5 text-2xl font-bold">صفحه اصلی</button>
        <button
          onClick={() => setShowOption(!ShowOption)}
          className={`block w-full ${
            ShowOption ? 'text-themeColor' : 'text-black'
          } bg-white rounded-xl mb-4 py-2.5 text-2xl font-bold`}>
          {/* <img src={Arrow}/> */}
          <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={`absolute ml-2 duration-150 ease-in-out ${ShowOption ? 'rotate-180 ' : 'rotate-0 '}`}>
            <path
              d="M15.3982 14.1479L19.6462 18.3959C19.871 18.6194 20.1751 18.7449 20.4922 18.7449C20.8092 18.7449 21.1133 18.6194 21.3382 18.3959C21.4506 18.2844 21.5399 18.1516 21.6008 18.0054C21.6617 17.8592 21.6931 17.7023 21.6931 17.5439C21.6931 17.3855 21.6617 17.2287 21.6008 17.0824C21.5399 16.9362 21.4506 16.8035 21.3382 16.6919L16.2502 11.6039C16.1386 11.4914 16.0059 11.4022 15.8596 11.3413C15.7134 11.2803 15.5566 11.249 15.3982 11.249C15.2397 11.249 15.0829 11.2803 14.9367 11.3413C14.7904 11.4022 14.6577 11.4914 14.5462 11.6039L9.39816 16.6919C9.28694 16.804 9.19895 16.937 9.13923 17.0832C9.07951 17.2294 9.04924 17.386 9.05015 17.5439C9.04924 17.7018 9.07951 17.8584 9.13923 18.0046C9.19895 18.1508 9.28694 18.2838 9.39816 18.3959C9.62299 18.6194 9.92713 18.7449 10.2442 18.7449C10.5612 18.7449 10.8653 18.6194 11.0902 18.3959L15.3982 14.1479Z"
              fill={ShowOption ? '#2A9134' : '#000'}
            />
          </svg>
          محتویات
        </button>
        {ShowOption && (
          <>
            <button
              onClick={() => navigate('/Books')}
              className="block w-full bg-[#EEF7EF] rounded-xl mb-4 py-2 text-2xl font-bold">
              كتاب ها
            </button>
            <button
              onClick={() => navigate('/articles')}
              className="block w-full bg-[#EEF7EF] rounded-xl mb-4 py-2 text-2xl font-bold">
              مقالات
            </button>
          </>
        )}
        <button className="block w-full bg-white rounded-xl mb-4 py-2.5 text-2xl font-bold">هویت ما</button>
        <button className="block w-full bg-white rounded-xl mb-4 py-2.5 text-2xl font-bold">در باره این سایت</button>
        <button className="block w-full bg-white rounded-xl mb-4 py-2.5 text-2xl font-bold">نظرات و پیشنهادات</button>
      </div>
      <div className="flex justify-center mt-16 mb-10 w-full">
        <button className="bg-white rounded-xl p-2 m-2">
          <img src={Instagram} className="w-7" />
        </button>
        <button className="bg-white rounded-xl p-2 m-2">
          <img src={Twitter} className="w-7" />
        </button>
        <button className="bg-white rounded-xl p-2 m-2">
          <img src={FacebookLogo} className="w-7" />
        </button>
      </div>
    </div>
  );
}

export default Drawer;

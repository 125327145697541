import React from 'react';

function ButtonSpinner({color}) {
  return (
    <div
      class={`spinner w-5 h-5 rounded-full animate-spin absolute
    border-2 border-solid border-t-transparent ${color ? `border-[${color}]` :  `border-inherit`} `} style={{border: `2px solid ${color}`, borderTopColor:'transparent'}}></div>
  );
}

export default ButtonSpinner;
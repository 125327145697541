import { CATEGORY_DATA, GET_CATEGORY_LOADER, SUB_CATEGORY_DATA } from '../action/Category';

const initialState = {
  CategoryData: false,
  subCategoryData: false,
  getCategoryLoader: true,
};

const ContentReducer = (state = initialState, action) => {
  switch (action.type) {
    case CATEGORY_DATA:
      return {
        ...state,
        CategoryData: action.payload,
      };
    case SUB_CATEGORY_DATA:
      return {
        ...state,
        subCategoryData: action.payload,
      };
    case GET_CATEGORY_LOADER:
      return {
        ...state,
        getCategoryLoader: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
};
export default ContentReducer;

import React, { useEffect } from 'react';
import Search from '../../assests/images/Search.png';
import { debounce } from 'debounce';
function Searchbar({ onChange, text, searchText, setSearchText }) {
  useEffect(() => {
    const url = new URL(window.location);
    const text = url.searchParams.get('text');
    setSearchText(text);
  }, []);
  return (
    <div className="md:w-[540px] bg-white px-4 pl-6 md:px-8 py-3 my-8 md:my-16 mx-5 md:mx-auto rounded-lg flex justify-between items-center">
      <img
        src={Search}
        style={{
          height: '20px',
          width: '20px',
        }}
      />
      <input
        className="focus:outline-none text-right w-4/5 text-xl placeholder:font-naskh-arabic placeholder:text-[#034404]"
        placeholder="جستجو در سایت"
        value={searchText}
        onChange={ev => {
          debounce(onChange(ev), 200);
          setSearchText(ev.target.value);
        }}
      />
    </div>
  );
}

export default Searchbar;

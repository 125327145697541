import React from 'react';
import FacebookLogo from '../../assests/images/Facebooklogo.svg';
import Instagram from '../../assests/images/instagram.svg';
import Twitter from '../../assests/images/twitter.svg';
import Logo from '../../assests/images/Logo.svg';
import { useNavigate } from 'react-router';
function Footer() {
  const navigate = useNavigate();

  return (
    <div className="absolute bottom-0 w-full bg-[#F9F9F9] md:bg-[#0E3A1F]">
      <div className="lg:container lg:px-0 mx-auto  text-right p-8 flex md:flex-row flex-col-reverse items-center">
        <div className="md:w-[320px] lg:w-fit flex gap-4 md:text-white font-[700] md:font-[500] md:text-xl lg:text-[24px]">
          <button onClick={() => navigate('/contact-us')}>تماس با ما</button>
          <button onClick={() => navigate('/terms-and-conditions')}>قوانین و شرایط</button>
          <button onClick={() => navigate('/characteristic')}>خصوصیت </button>
        </div>
        <a
          className="md:text-white text-sm absolute bottom-2 left-1/2 transform -translate-x-1/2 xl:-translate-x-[80%] font-poppins underline"
          href="https://www.reallybeyond.com/">
          Created By ReallyBeyond
        </a>
        <div className="flex md:flex-row flex-col-reverse items-center w-full flex-1 md:w-2/4 justify-end">
          <div className="mr-5 hidden lg:block">
            <button className="bg-white opacity-80 rounded-md p-1 m-1">
              <img src={Instagram} className="w-5" />
            </button>
            <button className="bg-white opacity-80 rounded-md p-1 m-1">
              <img src={Twitter} className="w-5" />
            </button>
            <button className="bg-white rounded-md p-1 m-1">
              <img src={FacebookLogo} className="w-5" />
            </button>
          </div>
          <div className="md:mr-5 md:my-0 my-3">
            <img src={Logo} />
          </div>
          <p className="text-[#0E3A1E] font-[700] md:text-white md:font-[500] md:text-xl lg:text-[24px] ">
            حقوق هر گونه تغییر محفوظ است
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;

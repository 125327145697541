import React from 'react';
import PolygonArrow from '../../assests/images/PolygonArrow.png';
import './index.css';
function DropDown({bookData}) {
  return (
    <div className="dropdown-content2 shadow bg-white rounded-md max-h-[230px] overflow-auto">
      <img src={PolygonArrow} className="polygonArrow" />
      {bookData && bookData.map((a,i)=>{
        return a.status === 'approved' ?(
          <div key={i} className={'bg-gray-50 text-black w-full text-xl py-2 font-bold rounded-md'}>
            {a.bookName}
            </div>
          ):null
      })}
    </div>
  );
}

export default DropDown;

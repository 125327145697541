import {
  GET_SEARCHED_HADITH_LOADER,
  GET_SINGLE_VIEWED_LOADER,
  HADITH_OF_THE_DAY_DATA,
  HADITH_OF_THE_DAY_LOADER,
  MOST_VIEWED_HADITH_DATA,
  MOST_VIEWED_HADITH_DATA_COUNT,
  MOST_VIEWED_HADITH_LOADER,
  PREVIOUS_HADITH_COUNT,
  PREVIOUS_HADITH_OF_THE_DAY_DATA,
  PREVIOUS_HADITH_OF_THE_DAY_LOADER,
  SINGLE_POST_DATA_DATA,
  SINGLE_POST_DATA_NAVIAGTE,
  SINGLE_SEARCH_POST_DATA_DATA,
  SINGLE_SEARCH_POST_DATA_NAVIAGTE,
} from '../action/Hadith';

const initialState = {
  hadithOfTheDayData: false,
  previousHadithOfTheDayData: false,
  previousHadithCount: 0,
  mostViewedHadithData: 0,
  mostViewedHadithCount: 0,
  mostViewedHadithLoader: true,
  hadithOfTheDayLoader: true,
  previousHadithOfTheDayLoader: true,
  singlePostLoader: true,
  singlePost: false,
  anwanPostData: false,
  singleSearchPostData: false,
  singleSearchPostNavigate: false,
  singleSearchPostLoader: false,
};

const ContentReducer = (state = initialState, action) => {
  switch (action.type) {
    case HADITH_OF_THE_DAY_DATA:
      return {
        ...state,
        hadithOfTheDayData: action.payload,
      };
    case PREVIOUS_HADITH_OF_THE_DAY_DATA:
      return {
        ...state,
        previousHadithOfTheDayData: action.payload,
      };
    case PREVIOUS_HADITH_COUNT:
      return {
        ...state,
        previousHadithCount: action.payload,
      };
    case MOST_VIEWED_HADITH_DATA:
      return {
        ...state,
        mostViewedHadithData: action.payload,
      };
    case MOST_VIEWED_HADITH_DATA_COUNT:
      return {
        ...state,
        mostViewedHadithCount: action.payload,
      };
    case MOST_VIEWED_HADITH_LOADER:
      return {
        ...state,
        mostViewedHadithLoader: action.payload,
      };
    case HADITH_OF_THE_DAY_LOADER:
      return {
        ...state,
        hadithOfTheDayLoader: action.payload,
      };
    case PREVIOUS_HADITH_OF_THE_DAY_LOADER:
      return {
        ...state,
        previousHadithOfTheDayLoader: action.payload,
      };
    case GET_SINGLE_VIEWED_LOADER:
      return {
        ...state,
        singlePostLoader: action.payload,
      };
    case SINGLE_POST_DATA_DATA:
      return {
        ...state,
        singlePost: action.payload,
      };

    case SINGLE_POST_DATA_NAVIAGTE:
      return {
        ...state,
        anwanNavigate: action.payload,
      };
    case GET_SEARCHED_HADITH_LOADER:
      return {
        ...state,
        singleSearchPostLoader: action.payload,
      };
    case SINGLE_SEARCH_POST_DATA_DATA:
      return {
        ...state,
        singleSearchPostData: action.payload,
      };
    case SINGLE_SEARCH_POST_DATA_NAVIAGTE:
      return {
        ...state,
        singleSearchPostNavigate: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
};
export default ContentReducer;

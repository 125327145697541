import React from 'react';

function Spinner() {
  return (
    <div className="min-h-[50vh] flex justify-center items-center w-full">
      <div
        className="w-10 h-10 rounded-full animate-spin absolute
    border-[5px] border-solid border-[#0E3A1F] border-t-transparent"></div>
    </div>
  );
}

export default Spinner;

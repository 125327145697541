import { PDFDocument, StandardFonts, rgb } from 'pdf-lib';
import { EMAIL_ADDRESS, WEBSITE_ADDRESS } from '../constants';

export async function includeWebsiteLinksInPdf(pdfBytes) {
  const pdfDoc = await PDFDocument.load(pdfBytes);
  for (let i = 0; i < pdfDoc.getPageCount(); i++) {
    const page = pdfDoc.getPage(i);
    const content = `
            ${EMAIL_ADDRESS}   ${WEBSITE_ADDRESS}
          `;
    const { width } = page.getSize();
    const { font } = await pdfDoc.embedFont(StandardFonts.Helvetica);
    await page.drawText(content, {
      x: width / 2 - 200,
      y: 10,
      font,
      color: rgb(0.16470588235294117, 0.5686274509803921, 0.20392156862745098),
      size: 12,
      maxWidth: width - 100,
      lineHeight: 15,
    });
  }
  const modifiedPdfBytes = await pdfDoc.save();
  return new Blob([modifiedPdfBytes], { type: 'application/pdf' });
}
export const downloadFile = async (url, name, type, setDownloadLoader) => {
  name = (name && name.split('=').slice(1).join('=')) || name;

  if (url) {
    setDownloadLoader(true);
    fetch(url)
      .then(async response => {
        let blob;
        if (type === 'application/pdf') {
          blob = await includeWebsiteLinksInPdf(await response.arrayBuffer());
        } else {
          blob = await response.blob();
        }
        var FileSaver = require('file-saver');
        var blo1b = new File([blob], name, { type: type });
        FileSaver.saveAs(blo1b, name);
      })
      .finally(() => {
        setDownloadLoader(false);
      });
  }
};
export const dateFormatHadith = d => {
  const pad = int => {
    let a = String(int).length == 1 ? `0${int}` : int;
    return a;
  };
  let date = new Date(d).getDate();
  let month = new Date(d).getMonth() + 1;
  let year = new Date(d).getFullYear();
  return `${year}-${pad(month)}-${pad(date)}`;
};

export function copyFunction(text, e, id) {
  e.stopPropagation();
  // get the container
  const element = document.getElementById(id);
  // Create a fake `textarea` and set the contents to the text
  // you want to copy
  const storage = document.createElement('textarea');
  storage.value = text;
  element.appendChild(storage);

  // Copy the text in the fake `textarea` and remove the `textarea`
  storage.select();
  storage.setSelectionRange(0, 99999);
  document.execCommand('copy');
  element.removeChild(storage);
}

const persianDaysOfWeek = [
  'یک‌شنبه', // Sunday
  'دوشنبه', // Monday
  'سه‌شنبه', // Tuesday
  'چهارشنبه', // Wednesday
  'پنج‌شنبه', // Thursday
  'جمعه', // Friday
  'شنبه', // Saturday
];

export function getPersianDayOfWeek(date) {
  let dayIndex = new Date(date).getDay();
  if (dayIndex >= 0 && dayIndex < persianDaysOfWeek.length) {
    return persianDaysOfWeek[dayIndex];
  } else {
    return 'Invalid day index';
  }
}

const persianMonthNames = [
  'ژانویه',
  'فوریه',
  'مارس',
  'آوریل',
  'مه',
  'ژوئن',
  'ژوئیه',
  'اوت',
  'سپتامبر',
  'اکتبر',
  'نوامبر',
  'دسامبر',
];

export const persianDate = d => {
  let date = new Date(d).getDate();
  let month = persianMonthNames[new Date(d).getMonth()];
  let year = new Date(d).getFullYear();
  return `${date} ${month} ${year}`;
};

export const catTree = data => {
  const treeArr = data.categories.map(c => c.category_name);
  return {
    tree: treeArr.join(' > '),
    activeAnwan: treeArr.at(-1),
  };
};

export const breadCrumbTree = (main_categorie, categorie, sub_categorie, addsub_categorie) => {
  let parentCat;
  if (main_categorie) {
    parentCat = main_categorie;
  }
  let cat;
  if (categorie) {
    cat = categorie;
  }
  let subCat;
  if (sub_categorie) {
    subCat = sub_categorie;
  }
  let addSubCat;
  if (addsub_categorie) {
    addSubCat = addsub_categorie;
  }

  // let a = `${addSubCat ? `${addSubCat}` : ''}${subCat ? ` < ${subCat}` : ''}${cat ? ` < ${cat}` : ''}${parentCat ? ` < ${parentCat}  ` : ''}`
  return {
    parentCat,
    cat,
    subCat,
    addSubCat,
  };
};

// export const lineBreak = text => {
//   let str = text ? text : false;
//   if (str) {
//     let updatedStr = str.split('@@').join(' DouBleBreAk ').split('@').join(' SinGleBreAk ');
//     return updatedStr.split(' DouBleBreAk ').length
//       ? updatedStr.split(' DouBleBreAk ').map((a, i) => {
//           return (
//             <span>
//               {a.split(' SinGleBreAk ').length > 1 ? (
//                 a.split(' SinGleBreAk ').map((b, j) => {
//                   return (
//                     <span>
//                       {b}
//                       <br />
//                     </span>
//                   );
//                 })
//               ) : (
//                 <span>
//                   {a}
//                   <br />
//                   <br />
//                 </span>
//               )}
//             </span>
//           );
//         })
//       : null;
//   }
// };

export const lineBreak = text => {
  let str = text ? text : false;
  if (str) {
    let updatedStr = str.split('@@').join(' DouBleBreAk ').split('@').join(' SinGleBreAk ');
    return updatedStr.split(' DouBleBreAk ').length
      ? updatedStr.split(' DouBleBreAk ').map((a, i) => {
          return (
            <span>
              {a.split(' SinGleBreAk ').length > 1 ? (
                a.split(' SinGleBreAk ').map((b, j) => {
                  return (
                    <span>
                      {b}
                      <br />
                    </span>
                  );
                })
              ) : (
                <span>
                  {a}
                  <br />
                  <br />
                </span>
              )}
            </span>
          );
        })
      : null;
  }
};

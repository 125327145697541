import React, { useEffect, useState } from 'react';

import Header from '../../constant/Header';
import Footer from '../../constant/footer';
import ScrollToTop from '../../constant/ScrollToTop';
import { useDispatch, useSelector } from 'react-redux';
import { getSinglePostAction } from '../../module/action/Post';
import { catTree, copyFunction, downloadFile } from '../../module/ConstantFunctions';
import { useNavigate } from 'react-router';
import Spinner from '../../constant/Spinner';
import { assetURL } from '../../api/utils';
import SinlgeHadith from './SinlgeHadith';
import { getSingleMostViewedHadithAction } from '../../module/action/Hadith';
function HadithDetail() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const singlePost = useSelector(e => e.Hadith.singlePost);
  const anwanNavigate = useSelector(e => e.Hadith.anwanNavigate);
  const singlePostLoader = useSelector(e => e.Hadith.singlePostLoader);

  const [postDetail, setPostDetail] = useState({});
  const [next, setNext] = useState('');
  const [previous, setPrevious] = useState('');
  const [redirectURL, setRedirectURL] = useState('');

  const { activeAnwan } = postDetail.categories ? catTree(postDetail) : {};

  useEffect(() => {
    const url = new URL(window.location);
    const post = url.searchParams.get('postId');
    const redirectUrl = url.searchParams.get('redirectUrl');
    setRedirectURL(redirectUrl);

    dispatch(getSingleMostViewedHadithAction(post));

    const handlePopState = event => {
      // Your custom functionality here
      navigate(redirectUrl);
      // Perform your custom actions when the back button is clicked
    };

    window.onpopstate = handlePopState;

    // Clean up the event listener when the component unmounts
    return () => {
      window.onpopstate = null;
    };
  }, []);

  const singleUpdate = postid => {
    dispatch(getSingleMostViewedHadithAction(postid));
  };

  useEffect(() => {
    setPostDetail(singlePost ? singlePost : {});
  }, [singlePost]);

  useEffect(() => {
    if (anwanNavigate) {
      setPrevious(anwanNavigate.previous);
      setNext(anwanNavigate.next);
    }
  }, [anwanNavigate]);

  return (
    <div className="relative h-min-100">
      <Header page={0} limit={2} />
      {singlePostLoader ? (
        <Spinner />
      ) : (
        <SinlgeHadith
          navFunction={false}
          next={false}
          previous={false}
          activeAnwan={activeAnwan}
          postDetail={postDetail}
          setPostDetail={setPostDetail}
          showCatTree={true}
          head={
            <div className="mb-10 mt-10 flex justify-between">
              <p
                onClick={() => navigate('/MostViewed')}
                className="mt-2.5 underline underline-offset-4 text-base font-bold cursor-pointer">
                پربیننده ترین احادیث
              </p>
              <div>
                <p className="text-themeColor text-right text-4xl font-bold">پربیننده ترین حدیث</p>
              </div>
            </div>
          }
        />
      )}

      <ScrollToTop />
      <Footer />
    </div>
  );
}

export default HadithDetail;

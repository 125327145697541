import React, { useEffect, useState } from 'react';
import Header from '../../constant/Header';
import Footer from '../../constant/footer';
import Card from '../../constant/card';
import ScrollToTop from '../../constant/ScrollToTop';
import Pagination from '../../constant/pagination';
import { mostViewedHadithAction } from '../../module/action/Hadith';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../../constant/Spinner';
import { useNavigate } from 'react-router';
function MostViewed() {
  const navigate = useNavigate();
  let dispatch = useDispatch();
  const mostViewedHadithData = useSelector(e => e.Hadith.mostViewedHadithData);
  const mostViewedHadithCount = useSelector(e => e.Hadith.mostViewedHadithCount);
  const mostViewedHadithLoader = useSelector(e => e.Hadith.mostViewedHadithLoader);

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(4);
  const [postArr, setPostArr] = useState([]);
  useEffect(() => {
    dispatch(mostViewedHadithAction(page, limit));
  }, [page, limit]);

  useEffect(() => {
    if (mostViewedHadithData) {
      setPostArr(mostViewedHadithData);
    }
  }, [mostViewedHadithData]);

  return (
    <div className="relative h-min-100">
      <Header page={0} limit={2} />
      <div className="container mx-auto py-8 px-4 md:px-0">
        <div className="mb-10 md:mt-10 flex justify-between">
          <p className="underline underline-offset-8 text-base font-bold">{/* أحادیث روز */}</p>
          <div>
            <p className="text-themeColor text-right text-4xl font-bold">پربیننده ترین احادیث</p>
          </div>
        </div>
        {/* list */}
        {mostViewedHadithLoader ? (
          <Spinner />
        ) : (
          postArr.map((a, i) => (
            <Card
              data={a}
              index={i}
              shareUrl={
                'https://mail.google.com/mail/u/0/?tf=cm' +
                '&body=' +
                `https%3A%2F%2Feastgaheazadi.com%2FHadithDetail%3FpostNumber%3D${a.post_number?.trim()}%26postId%3D${
                  a._id
                }`
              }
              onClick={e => {
                let redirectUrl = window.location.pathname;
                navigate(
                  `/MostFavourite?postNumber=${a.post_number?.trim()}&postId=${a._id}&redirectUrl=${redirectUrl}`
                );
              }}
            />
          ))
        )}
        <Pagination page={page} setPage={setPage} limit={limit} count={mostViewedHadithCount} />
      </div>
      <ScrollToTop />
      <Footer />
    </div>
  );
}

export default MostViewed;

import React, { useEffect, useState } from 'react';
import Header from '../../constant/Header';
import Footer from '../../constant/footer';
import Card from '../../constant/card';
import ScrollToTop from '../../constant/ScrollToTop';
import Pagination from '../../constant/pagination';
import { mostViewedHadithAction } from '../../module/action/Hadith';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../../constant/Spinner';
import { useNavigate } from 'react-router';
import { searchPostAction } from '../../module/action/Post';

const PostData = ({ postArr, setText, setPage, text }) => {
  const navigate = useNavigate();
  let dispatch = useDispatch();

  useEffect(() => {
    const url = new URL(window.location);
    const textParam = url.searchParams.get('text');
    const page = url.searchParams.get('page');
    setText(textParam);
    setPage(page ? Number(page - 1) : 0);
  }, []);
  return (
    <>
      {postArr.map((a, i) => (
        <Card
          data={a}
          index={i}
          shareUrl={
            'https://mail.google.com/mail/u/0/?tf=cm' +
            '&body=' +
            `https%3A%2F%2Feastgaheazadi.com%2FHadithDetail%3FpostNumber%3D${a.post_number?.trim()}%26postId%3D${a._id}`
          }
          onClick={e => {
            let redirectUrl = window.location.pathname;
            navigate(
              `/SearchResult?postNumber=${a.post_number?.trim()}&postId=${
                a._id
              }&redirectUrl=${redirectUrl}&text=${text}`
            );
          }}
        />
      ))}
    </>
  );
};

function SearchHadith() {
  const navigate = useNavigate();
  let dispatch = useDispatch();
  const getPostSearchData = useSelector(e => e.Post.getPostSearchData);
  const searchPostDataCount = useSelector(e => e.Post.searchPostDataCount);
  const getPostSearchLoader = useSelector(e => e.Post.getPostSearchLoader);

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(2);
  const [postArr, setPostArr] = useState([]);

  // const [url, setUrl] = useState(new URL(window.location));
  let [text, setText] = useState();

  useEffect(() => {
    if (getPostSearchData) {
      setPostArr(getPostSearchData);
    }
  }, [getPostSearchData]);

  useEffect(() => {
    const url = new URL(window.location);
    const text = url.searchParams.get('text');
    const pageNum = url.searchParams.get('page');
    dispatch(searchPostAction(text, Number(pageNum) > 1 ? Number(pageNum) - 1 : 0, limit, false));
  }, []);

  const changePage = i => {
    const url = new URL(window.location);
    const text = url.searchParams.get('text');
    const navigateFunc = () => {
      navigate(`/SearchHadith?text=${text}&page=${i + 1}`);
    };
    dispatch(searchPostAction(text, i, limit, navigateFunc));
    setPage(i);
  };

  return (
    <div className="relative h-min-100">
      <Header page={page} limit={limit} text={text} />
      <div className="container mx-auto py-8 px-4 md:px-0">
        <div className="mb-10 md:mt-10 flex justify-between">
          <p className="underline underline-offset-8 text-base font-bold">{/* أحادیث روز */}</p>
          <div>
            <p className="text-themeColor text-right text-4xl font-bold">{text} : جستجو کردن</p>
            <p className="text-themeColorDark text-right text-lg font-bold mt-2">{searchPostDataCount} : نتایج</p>
          </div>
        </div>
        {/* list */}
        {getPostSearchLoader ? (
          <Spinner />
        ) : (
          <PostData postArr={postArr} setText={setText} setPage={setPage} text={text} />
        )}
        <Pagination page={page} setPage={changePage} limit={limit} count={searchPostDataCount} />
      </div>
      <ScrollToTop />
      <Footer />
    </div>
  );
}

export default SearchHadith;

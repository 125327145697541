import React, { useState } from 'react';
import FacebookLogo from '../../assests/images/Facebooklogo.svg';
import Whatsapp from '../../assests/images/Whatsapp.svg';
import Twitter from '../../assests/images/twitter.svg';
import Mail from '../../assests/images/Mail.svg';
import Copy from '../../assests/images/Copy.svg';
import Download from '../../assests/images/download.svg';
import RightArrow from '../../assests/images/RightArrow.png';
import LeftArrow from '../../assests/images/LeftArrowWhite.png';
import CopyWhite from '../../assests/images/copyWhite.svg';
import DownloadWhite from '../../assests/images/downloadWhite.svg';
import Play from '../../assests/images/play.svg';
import pause from '../../assests/images/pause.png';
import Tooltip from '../../constant/Tooltip';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { assetURL } from '../../api/utils';
import { catTree, copyFunction, lineBreak } from '../../module/ConstantFunctions';
import ButtonSpinner from '../../constant/ButtonSpinner';
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';
import { downloadPost } from '../../module/action/Post';
import { swapNumbersInString } from '../../util';
function SinlgeHadith({
  navFunction,
  next,
  nextFunction,
  previous,
  previousFunction,

  postDetail,
  setPostDetail,
  showCatTree,
  hadithPath,
  activeAnwan,
}) {
  const dispatch = useDispatch();
  const [CopyHover, setCopyHover] = useState(false);
  const [DownloadHover, setDownloadHover] = useState(false);
  const [downloadLoader, setDownloadLoader] = useState(false);
  const [PlayHover, setPlayHover] = useState(false);
  const [leftHover, setleftHover] = useState(false);
  const [rightHover, setrightHover] = useState(false);
  const [played, setPlayed] = useState(false);
  const { tree = '' } = showCatTree && postDetail.categories ? catTree(postDetail) : {};
  function playAudio(e) {
    if (postDetail.recitation_audio || postDetail.recitation_audio_url) {
      e.stopPropagation();
      setPlayed(true);
      var x = document.getElementById('myAudio');
      x.play();
    }
  }

  function pauseAudio(e) {
    if (postDetail.recitation_audio || postDetail.recitation_audio_url) {
      e.stopPropagation();
      setPlayed(false);
      var x = document.getElementById('myAudio');
      x.pause();
    }
  }

  const print = objParam => {
    //  console.log(objParam);
    dispatch(downloadPost(objParam, setDownloadLoader));
  };

  postDetail = {
    ...postDetail,
    post_text: swapNumbersInString(postDetail?.post_text || ''),
    post_number: swapNumbersInString(postDetail?.post_number || ''),
    reference: swapNumbersInString(postDetail?.reference || ''),
  };

  return (
    <div className="container mx-auto p-4 md:px-0">
      <div className="mb-7 md:my-12">{hadithPath}</div>
      <div className={`w-full md:container mb-10 mx-auto  border-[#2A9134] text-right rounded-xl border p-8`}>
        <div className={`flex ${navFunction ? `justify-between` : `justify-center`}  w-full md:w-3/6 mx-auto mb-10`}>
          {navFunction ? (
            <button
              onMouseOver={() => setleftHover(true)}
              onMouseOut={() => setleftHover(false)}
              onClick={() => {
                nextFunction();
              }}
              disabled={!next}
              className={`shrink-0 relative w-16 h-16 flex ease-in duration-300 justify-center items-center md:p-4 border-[#0E3A1E3D] border-[2px] rounded-full hover:bg-[#2A9134] disabled:cursor-not-allowed`}>
              {leftHover && <Tooltip text={'بعد'} />}
              {leftHover ? <img src={LeftArrow} /> : <img src={RightArrow} className="rotate-180" />}
            </button>
          ) : null}
          <div className="text-xl md:text-3xl text-themeColorDark text-center font-bold">
            <p className="px-2">{activeAnwan}</p>
            <p>({postDetail.post_number})</p>
          </div>
          {navFunction ? (
            <button
              onMouseOver={() => setrightHover(true)}
              onMouseOut={() => setrightHover(false)}
              disabled={!previous}
              onClick={() => {
                previousFunction();
              }}
              className={`shrink-0 hover:bg-[#2A9134] relative w-16 h-16 flex justify-center ease-in duration-300 items-center p-4 border-[#0E3A1E3D] border-[2px] rounded-full disabled:cursor-not-allowed`}>
              {rightHover && <Tooltip text={'قبلی'} />}
              {rightHover ? <img src={LeftArrow} className="rotate-180" /> : <img src={RightArrow} />}
            </button>
          ) : null}
        </div>
        <div className="text-2xl leading-10 font-bold mb-5 flex justify-end">
          <p className="text-right" dir="rtl" id="postText">
            ({postDetail.post_number}) {lineBreak(postDetail.post_text?.replaceAll(/\n/g, '@'))}
          </p>
        </div>
        <p className="text-themeColorDark mb-5 font-bold text-base" dir="rtl">
          {postDetail.reference}
        </p>
        <p className="text-themeColorDark mb-3 font-bold text-lg" dir="rtl">
          {hadithPath || tree}
        </p>

        {/* card footer */}
        <audio id="myAudio">
          <source
            src={
              postDetail.recitation_audio_url
                ? postDetail.recitation_audio_url
                : `${assetURL}${postDetail.recitation_audio}`
            }
            type="audio/mp3"
          />
          <source
            src={
              postDetail.recitation_audio_url
                ? postDetail.recitation_audio_url
                : `${assetURL}${postDetail.recitation_audio}`
            }
            type="audio/mp3"
          />
          Your browser does not support the audio element.
        </audio>
        <div className="flex flex-col md:flex-row justify-between items-end md:items-center">
          <div className="mb-5 md:mb-0 flex items-center">
            <button
              onMouseOver={() => setCopyHover(true)}
              onMouseOut={() => setCopyHover(false)}
              className="relative hover:bg-[#0E3A1E] border-[#0E3A1E3D] border-[2px] ease-in duration-300  bg-white rounded-full border-2 p-2 m-1"
              onClick={e => {
                copyFunction(postDetail.post_text, e, `postText`);
              }}>
              {CopyHover && <Tooltip text={'کپی'} />}
              {CopyHover ? <img src={CopyWhite} width={'24px'} /> : <img src={Copy} width={'24px'} />}
            </button>
            <button
              disabled={downloadLoader}
              onMouseOver={() => setDownloadHover(true)}
              onMouseOut={() => setDownloadHover(false)}
              className="relative hover:bg-[#0E3A1E] border-[#0E3A1E3D] border-[2px] ease-in duration-300 bg-white rounded-full border-2 p-2 m-1 flex items-center justify-center"
              onClick={e => {
                e.stopPropagation();
                print(postDetail);
                // downloadFile(
                //   postDetail.recitation_audio_url
                //     ? postDetail.recitation_audio_url
                //     : `${assetURL}${postDetail.recitation_audio}`,
                //   postDetail.recitation_audio,
                //   'audio/mp3',
                //   setDownloadLoader
                // );
              }}>
              {downloadLoader ? <ButtonSpinner color={'#0E3A1E'} /> : null}

              <span
                className={
                  downloadLoader ? `opacity-0 flex items-center justify-center` : 'flex items-center justify-center'
                }>
                {DownloadHover && <Tooltip text={'دانلود'} />}
                {DownloadHover ? <img src={DownloadWhite} width={'24px'} /> : <img src={Download} width={'24px'} />}
              </span>
            </button>
            {played ? (
              <button
                onMouseOver={() => setPlayHover(true)}
                onMouseOut={() => setPlayHover(false)}
                className="relative hover:bg-[#0E3A1E] border-[#2A9134] border-[2px] ease-in duration-300 bg-[#2A9134] rounded-full border p-2 m-1"
                onClick={e => pauseAudio(e)}>
                {/* <Tooltip text={'بازخوانی'}/> */}
                {PlayHover && <Tooltip text={'بازخوانی'} />}
                <img src={pause} width={'24px'} />
              </button>
            ) : (
              (postDetail.recitation_audio_url || postDetail.recitation_audio) && (
                <button
                  onMouseOver={() => setPlayHover(true)}
                  onMouseOut={() => setPlayHover(false)}
                  className="relative hover:bg-[#0E3A1E] border-[#2A9134] border-[2px] ease-in duration-300 bg-[#2A9134] rounded-full border p-2 m-1"
                  onClick={e => playAudio(e)}>
                  {/* <Tooltip text={'بازخوانی'}/> */}
                  {PlayHover && <Tooltip text={'بازخوانی'} />}
                  <img src={Play} width={'24px'} />
                </button>
              )
            )}
          </div>
          <div className="flex items-center">
            <div>
              <button
                onClick={e => {
                  e.stopPropagation();
                  window.open(
                    'https://mail.google.com/mail/u/0/?tf=cm' +
                      '&body=' +
                      `https%3A%2F%2Feastgaheazadi.com%2FHadithDetail%3FpostNumber%3D${postDetail.post_number?.trim()}%26postId%3D${
                        postDetail._id
                      }`,
                    'Send Via Email',
                    'menubar=1,resizable=1,width=800,height=650, top=250, left=250'
                  );
                }}
                className="bg-white rounded-full border p-2 m-1 hover:bg-[#0E3A1E] border-[#0E3A1E3D] ease-in duration-300 [&>*]:hover:invert-[1] [&>*]:hover:contrast-200">
                <img src={Mail} width={'22px'} />
              </button>
              <WhatsappShareButton url={decodeURI(window.location.href)}>
                <button className="bg-white rounded-full border p-2 m-1 hover:bg-[#0E3A1E] border-[#0E3A1E3D] ease-in duration-300 [&>*]:hover:invert-[1] [&>*]:hover:contrast-200">
                  <img src={Whatsapp} width={'22px'} />
                </button>
              </WhatsappShareButton>
              <TwitterShareButton url={decodeURI(window.location.href)}>
                <button className="bg-white rounded-full border p-2 m-1 hover:bg-[#0E3A1E] border-[#0E3A1E3D] ease-in duration-300 [&>*]:hover:invert-[1] [&>*]:hover:contrast-200">
                  <img src={Twitter} width={'22px'} />
                </button>
              </TwitterShareButton>
              <FacebookShareButton url={decodeURI(window.location.href)}>
                <button className="bg-white rounded-full border p-2 m-1  hover:bg-[#0E3A1E] border-[#0E3A1E3D] ease-in duration-300 ">
                  <img src={FacebookLogo} width={'22px'} />
                </button>
              </FacebookShareButton>
            </div>
            <p className="ml-2 text-base font-bold">: مشارکت</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SinlgeHadith;

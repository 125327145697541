import {
  BOOK_DATA,
  GET_ALL_BOOK_LOADER,
  GET_BOOK_VOLUMES_DATA,
  GET_BOOK_VOLUMES_LOADER,
  GET_SINGLE_BOOK_DATA,
  GET_SINGLE_BOOK_LOADER,
} from '../action/Book';

const initialState = {
  bookData: [],
  getSingleBook: false,
  volumesData: false,
  getAllBookLoader: true,
  getBookVolumesLoader: true,
  getSingleBookLoader: true,
};

const ContentReducer = (state = initialState, action) => {
  switch (action.type) {
    case BOOK_DATA:
      return {
        ...state,
        bookData: action.payload,
      };
    case GET_SINGLE_BOOK_DATA:
      return {
        ...state,
        getSingleBook: action.payload,
      };
    case GET_BOOK_VOLUMES_DATA:
      return {
        ...state,
        volumesData: action.payload,
      };
    case GET_ALL_BOOK_LOADER:
      return {
        ...state,
        getAllBookLoader: action.payload,
      };
    case GET_BOOK_VOLUMES_LOADER:
      return {
        ...state,
        getBookVolumesLoader: action.payload,
      };

    case GET_SINGLE_BOOK_LOADER:
      return {
        ...state,
        getSingleBookLoader: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
};
export default ContentReducer;

import axios from 'axios';

export const GET_ARTICLE_LOADER = 'GET_ARTICLE_LOADER';
export const getArticleLoader = bool => ({
  type: 'GET_ARTICLE_LOADER',
  payload: bool,
});

export const GetArticleAction = (page, limit) => {
  return async dispatch => {
    // dispatch(addArticleLoader(true))
    dispatch(getArticleLoader(true));

    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `/api/v1/admin/articles/getall?page=${page}&limit=${limit}`,
      headers: {},
    };

    axios(config)
      .then(response => {
        dispatch(GetArticleData(response.data.data));
        dispatch(GetArticleCount(response.data.count));
        dispatch(getArticleLoader(false));
      })
      .catch(error => {
        dispatch(getArticleLoader(false));
      });
  };
};
export const GET_ARTICLE_DATA = 'GET_ARTICLE_DATA';
export const GetArticleData = payload => ({
  type: 'GET_ARTICLE_DATA',
  payload: payload,
});
export const GET_ARTICLE_COUNT = 'GET_ARTICLE_COUNT';
export const GetArticleCount = payload => ({
  type: 'GET_ARTICLE_COUNT',
  payload: payload,
});
